import request from '@/utils/request';

export function getList(data) {
  return request({
    url: '/appointment-address/getList',
    method: 'post',
    data
  });
}

export function save(data) {
  return request({
    url: '/appointment-address/save',
    method: 'post',
    data
  });
}

export function del(data) {
  return request({
    url: '/appointment-address/del',
    method: 'post',
    data
  });
}

/**
 * 地名分组
 */
export function getAddressName() {
  return request({
    url: '/appointment-address/getAddressName',
    method: 'post'
  });
}

/**
 * 根据ID查询
 * */
export function getAddresInfo(data) {
  return request({
    url: '/appointment-address/getInfo',
    method: 'post',
    data
  });
}
