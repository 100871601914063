import request from '@/utils/request';

export function getRoleList(data) {
  return request({
    url: '/admin-role/getList',
    method: 'post',
    data
  });
}

export function save(data) {
  return request({
    url: '/admin-role/save',
    method: 'post',
    data
  });
}

export function del(data) {
  return request({
    url: '/admin-role/del',
    method: 'post',
    data
  });
}

export function assignedRoleMenu(data) {
  return request({
    url: '/admin-role/assignedRoleMenu',
    method: 'post',
    data
  });
}

export function getRoleMenuList(data) {
  return request({
    url: '/admin-role/getRoleMenuList',
    method: 'post',
    data
  });
}
