import request from '@/utils/request';

/**
 *
 * @param {用户登录接口} data
 */
export function login(data) {
  return request({
    url: '/admin-user/login',
    method: 'post',
    data
  });
}

/**
 *
 * @param {保存} data
 */
export function saveUser(data) {
  return request({
    url: '/admin-user/save',
    method: 'post',
    data
  });
}

/**
 * 获取用户列表
 * @param {条件查询} data
 */
export function getUserList(data) {
  return request({
    url: '/admin-user/getList',
    method: 'post',
    data
  });
}

/**
 *
 * @param {删除} data
 */
export function delUser(data) {
  return request({
    url: '/admin-user/del',
    method: 'post',
    data
  });
}

/** 是否激活状态
 * @param {ID} id
 * @param {状态} isActive
 */
export function updateIsActive(id, isActive) {
  return request({
    url: '/admin-user/updateIsActive',
    method: 'post',
    params: {
      id: id,
      isActive: isActive
    }
  });
}

/**
 * 根据用户ID加载主菜单界面
 * @param {Object} data
 */
export function getMenuListByUserid(data) {
  return request({
    url: '/admin-user/getMenuListByUserid',
    method: 'post',
    data
  });
}

/**
 * 根据角色编号和部门ID查询
 * @param {Object} data
 */
export function getListByRoleAndDepart(data) {
  return request({
    url: '/admin-user/getListByRoleAndDepart',
    method: 'post',
    data
  });
}

/**
 * 根据用户ID加载首页选项卡TAB
 * @param {Object} data
 */
export function getTabListByUserid(data) {
  return request({
    url: '/admin-user/getTabListByUserid',
    method: 'post',
    data
  });
}

/**
 * 修改密码
 * @param {Object} data
 */
export function updatePassword(data) {
  return request({
    url: '/admin-user/updatePassword',
    method: 'post',
    data
  });
}


/**
 * 管理端日志
 * @param {Object} data
 */
export function getLogList(data) {
  return request({
    url: '/log/getList',
    method: 'post',
    data
  });
}


/**
 * 小程序端日志
 * @param {Object} data
 */
export function getLogListWx(data) {
  return request({
    url: '/log/getLogList',
    method: 'post',
    data
  });
}
