/**验证手机号码*/
var checkPhone = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('手机号不能为空'));
  } else {
    const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('请输入正确的手机号'));
    }
  }
};

/**验证身份证号码**/
var checkIdCard = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('身份证号码不能为空'));
  } else {
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('请输入正确的身份证号码'));
    }
  }
};

/**真实姓名验证**/
var checkRealName = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('真实姓名不能为空'));
  } else {
    const reg = /^[\u4e00-\u9fa5]{2,4}$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('请输入正确的姓名'));
    }
  }
};

export default {
  checkPhone,
  checkIdCard,
  checkRealName
};
